import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import AddSuspensionPoint from 'src/components/add-suspension-point/add-suspension-point';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const AddViolationPage = () => {
  return (
    <Router basepath="/players/:id/suspensions/violations">
      <AddViolationRoute path="/add" />
    </Router>
  );
};

interface AddViolationRouteProps extends RouteComponentProps {
  id?: string;
}

const AddViolationRoute: React.FC<AddViolationRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Add violation" />
      <PrivateRoute>
        <AddSuspensionPoint />
      </PrivateRoute>
    </Layout>
  );
};

export default AddViolationPage;
